<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6 col-lg-4">
        <div class="my-5">
          <Logo></Logo>

          <PageTitle :title="'Create an account'" class="text-center"></PageTitle>

          <form
            @submit.prevent="createAccount"
            :class="isLoading ? 'is-busy' : ''"
            class="my-4"
          >
            <ErrorMessage v-if="error" :error="error"></ErrorMessage>

            <div class="my-3 form-floating">
              <input
                v-model.trim="form.email"
                :disabled="isFromInvitation"
                type="email"
                class="form-control"
                id="usermail"
                placeholder="user@domain.com"
              >
              <label for="usermail">Email address<sup class="text-danger">*</sup></label>
              <div class="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>

            <div class="row g-2">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    v-model.trim="form.password"
                    type="password"
                    class="form-control"
                    id="userpass"
                    placeholder="password"
                  >
                  <label for="userpass">Password<sup class="text-danger">*</sup></label>
                  <div class="form-text">Minimum 6 characters.</div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    v-model.trim="form.passwordConfirm"
                    type="password"
                    class="form-control"
                    id="userpass2"
                    placeholder="confirm"
                  >
                  <label for="userpass">Confirm password<sup class="text-danger">*</sup></label>
                </div>
              </div>
              <div v-if="passwordMismatched" class="invalid-feedback d-block">
                Password confirmation does not match.
              </div>
            </div>

            <hr>

            <div class="my-3 form-floating">
              <select
                v-model.trim="form.type"
                :disabled="isFromInvitation"
                class="form-select"
                id="usertype"
              >
                <option disabled value="">Please select one</option>
                <option value="athlete">An athlete</option>
                <option value="coach">A coach</option>
              </select>
              <label for="usertype">Which one describes you?<sup class="text-danger">*</sup></label>
            </div>

            <div class="row g-2">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    v-model.trim="form.firstName"
                    type="text"
                    class="form-control"
                    id="firstname"
                    placeholder="First name"
                  >
                  <label for="firstname">First name<sup class="text-danger">*</sup></label>
                  <div class="form-text">Minimum 3 characters.</div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    v-model.trim="form.lastName"
                    type="text"
                    class="form-control"
                    id="lastname"
                    placeholder="Last name"
                  >
                  <label for="lastname">Last name<sup class="text-danger">*</sup></label>
                </div>
              </div>
            </div>

            <SubmitButton
              :text="'Submit'"
              :textBusy="'Please wait...'"
              :isLoading="isLoading"
              :disabled="isLoading || isSubmitDisabled"
              class="btn btn-primary my-3"
            ></SubmitButton>

            <input v-model.trim="form.invitationCode" type="hidden" class="form-control" disabled>

            <input v-model.trim="form.plan" type="hidden" class="form-control" disabled>

            <div class="my-3 text-muted smaller">
              By creating an account, you agree to the <a href="https://trainifier.co/terms/" target="_blank">Terms of Service</a> and the <a href="https://trainifier.co/privacy-policy/" target="_blank">Privacy Policy</a>
            </div>
          </form>

          <div class="my-5 pt-5 border-top text-center">
            <router-link
              to="/login"
              class="link-primary"
            >Already have an account? Sign in</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Register',
  components: {
    Logo: () => import('@/components/Logo'),
    PageTitle: () => import('@/components/PageTitle'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    email() {
      const { email } = this.$route.query;
      if (email) {
        return decodeURIComponent(email);
      }
      return null;
    },
    invitationCode() {
      return this.$route.params.invitationCode;
    },
    plan() {
      return this.$route.params.plan;
    },
    isFromInvitation() {
      return this.email && this.invitationCode;
    },
    isSubmitDisabled() {
      const {
        email, password, passwordConfirm, type, firstName, lastName,
      } = this.form;
      return email === ''
        || password === ''
        || password.length < 6
        || password !== passwordConfirm
        || type === ''
        || firstName === ''
        || firstName.length < 3
        || lastName === '';
    },
    passwordMismatched() {
      const { password, passwordConfirm } = this.form;
      return password !== '' && passwordConfirm && password !== passwordConfirm;
    },
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      this.error = null;
      try {
        await this.$store.dispatch('auth/register', this.form);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      form: {
        email: '',
        password: '',
        passwordConfirm: '',
        type: '',
        firstName: '',
        lastName: '',
        invitationCode: '',
        plan: '',
      },
    };
  },
  mounted() {
    const { email, invitationCode, plan } = this;

    if (email) {
      this.form.email = email;
      this.form.type = 'athlete';
    }

    if (plan) {
      this.form.plan = plan;
    } else {
      this.form.plan = 'starter';
    }

    if (invitationCode) {
      this.form.invitationCode = invitationCode;
    }
  },
};
</script>
